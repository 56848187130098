<template>
  <div class="w-100">
    <Navbar :pagetitle='pagetitle' :navitems="navitems" @downloadCall="showDowns=!showDowns" />
    <Download v-if="showDowns" @close="showDowns=false" />
    <Aside :data="first" />
    <Aside :data="second" />
    <Aside :data="third" />
    <Modal v-if="showModal" @close="showModal=false" />
    <Footer  @modalCall="showModal=true" />
  </div>
</template>

<script>

import Modal    from '@/components/Modal'
import Navbar   from '@/components/Navbar'
import Footer   from '@/components/Footer'
import Download from '@/components/Downloads'
import Aside    from '@/components/ImageAside'
import Img1     from '@/assets/resized/nuez/6.png'
import Img2     from '@/assets/resized/nuez/11.png'
import Img3     from '@/assets/resized/nuez/7.png'

  export default ({
      name: 'Settings',
      components: { Modal, Download, Navbar, Footer, Aside },
      data(){ return {
        showDowns:false,
        showModal:false,
        pagetitle: 'Setup', 
        navitems: [
            { title: 'nuez', route: 'nuez' },
            { title: 'gui',  route: 'gui' }, 
            { title: 'settings', route: 'settings' }, 
            { title: 'mongodb',  route: 'mongodb' },
        ],

        first: {
            side: 'right',
            title: 'Setup',
            description: 'Where to start after installation.',
            section: [
              {
                icon: 'far fa-smile-beam',
                text: '..so you´ve installed your copy and want to start right away. hey! i´m giving you a headstart. in the early mongo-versions there´ll be my db-login preset, so you can see what it´s like..',
              },
              {
                icon: 'far fa-grin-squint',
                text: 'head over to settings. the image will be highlighted as you hover over it, click it! select your own avatar.',
              },
              {
                icon: 'far fa-address-card',
                text: 'your alias will never be ´fix´. you can change it after each post, i.e. ..happy trolling! (..okay, got your worries, but remember that ´freedom of speech´ thang? ..for everyone ;).. your email, once set up and used, you should not change: you need it for creating a new group and maintaining the group-page..',
              },
            ],
            image: Img1,
        },

        second: {
            side: 'left',
            title: '',
            description: 'Your own MongoDB!',
            section: [
              {
                icon: 'fas fa-box-open',
                text: 'so.. open pandoras box. i will give you a quick runthrough how to set up your own mongodb online on the next page. 512MB are free and it will take some time until you use them up with text and some resized images..',
              },
              {
                icon: 'fas fa-radiation-alt',
                text: '..remember? ..do not upload videos or other huge files with this app. your machine will smoke up with computing power and up- & download will take aeons. ..but you can also use a more extensive version for a fee.',
              },
              {
                icon: 'far fa-save',
                text: 'after pressing the ´save´-button at the end of the page the pre-set data will be gone and you will log into your server.',
              },
          ],
            image: Img2,
        },

        third: {
          side: 'right',
            title: '',
            description: 'Enryption.',
            section: [
              {
                icon: 'fas fa-shield-alt',
                text: 'safety is an illusion, especially online. for each of your channels you will have a tab at the end of the page. ',
              },
              {
                icon: 'fas fa-unlock-alt',
                text: 'to paraphrase the great greta: how dare you?! ..choose a simple passphrase?! to simplify creating one i put a slider beyond the input, activate it by clicking the checkbox, let it roll.. save all settings.',
              },
              {
                icon: 'fas fa-lock',
                text: 'like i already said: safety is an illusion. everything is hackable. but let´s make it as complicated as humanly possible for them.',
              },
          ],
            image: Img3,
        }
      }}
  })
</script>

<style scoped>
</style>