<template>
<div :class="['layout-text-aside', data.side=='left'?'bg-med':'']" id="new_2">

    <div v-if="data.side==='left'" class="my-auto flex-center">
        <div class="p-2 wrap-image">
            <img :src="data.image" :class="['glow', larger?'alternate':'']" width="auto" alt="desktop" @mouseover="larger=true" @mouseleave="larger=false">
        </div>
    </div>

    <div class='wrap-text'>
      <div class="pt-3">
          <h3 v-if="data.title" class="semibold display-4">{{ data.title }}</h3>
      </div>
      
      <div class="line" />
      <p  v-if="data.description" class="regular display-8">{{ data.description }}</p>
      <div class="card">
          <div v-for="(card, index) in data.section" :key="index" class="card-content mb-3">
            <div class="d-flex flex-row justify-content-between">
              <i :class="['iconfont', card.icon, 'fa-2x', data.side=='left'?'me-4':'', data.side=='right'?'me-4':'']"></i>
              <p class="card-text regular display-7">{{ card.text }}</p>
            </div>
          </div>
      </div>
    </div>

    <div v-if="data.side==='right'" class="pt-5 flex-center">
        <div class="p-2 my-auto wrap-image">
            <img :src="data.image" :class="['glow', larger?'enlarge':'']" width="auto" alt="desktop" @mouseover="larger=true" @mouseleave="larger=false">
        </div>
    </div>

</div>
</template>
<script>
    export default({
        name: 'ImageRight',
        props: [ 'data' ],
        data(){ return { larger:false, }}
    })
</script>
<style scoped>

  .iconfont {
      font-size: 2rem;
  }
  .half {
      width: 50vw;
  }

  .icon-wrapper{
      margin: auto;
  }

  .glow {
    box-shadow: 0 0 1px 1px rgba(255,255,255,.3);
  }

@media(orientation: landscape){
    .layout-text-aside{
        display:flex;
        flex-direction:row;
        align-content:center;
        padding: 60px;
    }
    .layout-text-aside .wrap-text {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding: 0 3%;
    }

    .layout-text-aside .wrap-image {
        width: 40vw;
        margin: auto;
        padding: 0 3%;
    }

    
  .layout-text-aside .enlarge {
    transform: scale(1.15);
    transition: transform 2s;
  }

  .layout-text-aside .alternate {
    transform: scale(1.15);
    transition: transform 2s;
  }

  .glow {
    box-shadow: 0 0 10px 10px rgba(255,255,255,.3);
  }
}

@media(orientation: portrait){

  .layout-text-aside {
      display:flex;
      flex-direction:column;
      align-content: center;
      justify-content: center;
      padding: 40px 30px;
      font-size: .85rem;
  }
   
  .layout-text-aside .enlarge {
    transform: scale(1.15);
    transition: transform 2s;
  }

  .layout-text-aside .alternate {
    transform: scale(1.15);
    transition: transform 2s;
  }

  .glow {
    box-shadow: 0 0 10px 10px rgba(255,255,255,.3);
  }
}


</style>